const faqLinkLanguage = () => {

  let browserLanguage = localStorage.getItem('i18nextLng');

  let hyperlink;
  switch (browserLanguage) {
    case 'en' || 'us':
      hyperlink = 'https://dchealth.dc.gov/page/digital-vaccine-record-faqs';
      break;
    case 'es':
      hyperlink = 'https://dchealth.dc.gov/page/digital-vaccine-record-faqs';
      break;
    case 'cn':
      hyperlink = 'https://dchealth.dc.gov/page/digital-vaccine-record-faqs';
      break;
    case 'ae':
      hyperlink = 'https://dchealth.dc.gov/page/digital-vaccine-record-faqs';
      break;
    case 'kr':
      hyperlink = 'https://dchealth.dc.gov/page/digital-vaccine-record-faqs';
      break;
    case 'ph':
      hyperlink = 'https://dchealth.dc.gov/page/digital-vaccine-record-faqs';
      break;
    case 'tw':
      hyperlink = 'https://dchealth.dc.gov/page/digital-vaccine-record-faqs';
      break;
    case 'vi':
      hyperlink = 'https://dchealth.dc.gov/page/digital-vaccine-record-faqs';
      break;
    default:
      // default is english
      hyperlink = 'https://dchealth.dc.gov/page/digital-vaccine-record-faqs';
  }

  return hyperlink;
}

export default faqLinkLanguage;