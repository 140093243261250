import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import QRData from "./QRData";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from "react-i18next";

const QrScreen = (props) => {
  const { lang } = useParams();
  const id = props.location.pathname.split("/").splice(3).join("/");

  const [user, setUser] = useState(null);
  const [qr, setQr] = useState(null);
  const [healthCard, setHealthCard] = useState();
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState({});

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };


  useEffect(() => {
    changeLanguage(lang);
  }, [lang]);

  
  const [error, setError] = useState({
    FirstName: false,
    LastName: false,
    Phone: false,
    Email: false,
    Pin: false,
    Date: false
  })
  const CREDENTIALS_API_QR = process.env.REACT_APP_CREDENTIALS_API_QR;

  const submitPin = (event) => {
    event.preventDefault();
    const credentialData = {
      Id: id,
      Pin: pin,
      Type: "Status",
      Key: process.env.REACT_APP_API_KEY
    }
    setLoading(true);
    fetch(`${CREDENTIALS_API_QR}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentialData)
    }).then(async response =>{ 
      let res = await response.json()
      if (res.status === 400) {
        setErrorMessage({ type: 'pinErrorMsg3', message: "The PIN entered is invalid. Please retry by clicking the link provided to you to re-enter your PIN." });
        setLoading(false);
      }else if (response.status === 499) {
        setLoading(false);
        setResponseMessage({ type: 'pinErrorMsg8', message: "The Digital COVID-19 Vaccine Record application is temporarily unavailable due to system maintenance." });
      }
      else if (res.status === 429) {
        setErrorMessage({ type: 'pinErrorMsg4', message: "Entry Expired, Please submit another vaccine records request." });
        setLoading(false);
      }
      else if (res.status === 422) {
        setErrorMessage({ type: 'pinErrorMsg5', message: "Please contact CDPH for more info on your vaccine records." });
        setLoading(false);
      }else if (res.status === 499){
        setLoading(false);
        setResponseMessage({ type: 'pinErrorMsg8', message: "The Digital COVID-19 Vaccine Record application is temporarily unavailable due to system maintenance." });
      }
      else if (res.status == 200) {
        return res.body
      }else{
        setLoading(false);
        setResponseMessage({ type: 'pinErrorMsg6', message: "Could not complete your request, please try again." });
      }
    }).then((data) => {
          setUser(data);
          setQr(data.fileContentQr, data.mimeTypeQr);
          setHealthCard(
            data.fileContentSmartCard, data.mimeTypeSmartCard
          );
          setLoading(false);
      }).catch((err) => {
        setLoading(false);
      });
  };
  const numbersOnly = (e) => {
    if (e.target.value.length === 4) {
      e.target.style.background = "repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat";
      setError({ ...error, Pin: false });
    }
    const numsOnly = e.target.value.replace(/[^0-9]/g, "");
    setPin(numsOnly);
  };

  const useStyles = makeStyles({
    underline: {
      "&&&:before": {
        borderBottom: "none"
      },
      "&&:after": {
        borderBottom: "none"
      }
    }
  });
  const classes = useStyles();

  
  if (user !== null && qr !== null) {
    return (
      <div className="bodyContainer qrContainer">
        <QRData
          user={user}
          qr={qr}
        />
      </div>
    );
  } else {
    return (
      <section className="bodyContainer qrContainer">
        <article>
        <div className="pin-container" style={{ margin: "30px" }}>
        <form onSubmit={event=>submitPin(event)} id={"main"}>
          <Card
            className="MuiRootCard"
            style={{ border: "none", boxShadow: "none" }}
          >
            <label htmlFor={'partitioned'} style={{ display: 'block' }}>
              <h1
                style={{ color: "#F06724", fontSize: "24px", marginBottom: "25px" }}
              >
                <Trans i18nKey="qrpage.pincode">PIN CODE:</Trans>
              </h1>
            </label>
            <div>
              <p style={{ marginLeft: "0" }}>
                <Trans i18nKey="vaccineform.enterPin">Please enter the PIN code you created to request access to your
                  vaccine record.</Trans>
              </p>
            </div>
            <TextField
              inputProps={{
                autoComplete: "off",
                type: 'tel',
                name: "PIN",
                value: pin,
                onChange: numbersOnly,
                maxLength: 4,
                minLength: 4,
                required: true,
                onBlur: (e) => e.target.value.length < 4 ? [e.target.style.background = "repeating-linear-gradient(90deg, #f44336 0, #f44336 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat", setError({ ...error, Pin: true })] : [e.target.style.background = "repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat", setError({ ...error, Pin: false })]
              }}
              InputProps={{
                className: classes.underline
              }}
              id="partitioned"
            />
            <CardActions style={{ padding: "8px 0px" }}>
              {loading ? (
                <CircularProgress />
              ) : (
                <button
                  style={{
                    borderRadius: "50px",
                    padding: '10px 30px',
                    backgroundColor: pin ? "#22489C" : "gray",
                    color: "white",
                    height: '50px',
                    margin: "0px",
                    marginTop: "30px",
                    width: '123px',
                  }}
                  type="submit"
                  size="small"
                  disabled={!pin}
                >
                  <Trans i18nKey="vaccineform.submitbutton">Submit</Trans>
                </button>
              )}
            </CardActions>
          </Card>
          <div style={{ color: 'red' }}>{errorMessage.message ? <Trans i18nKey={`vaccineform.${errorMessage.type}`}>{errorMessage.message}</Trans> : ''}</div>
        </form>
        </div>
        </article>
      </section>
    );
  }
};

export default QrScreen;
